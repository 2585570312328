import UAParser from 'ua-parser-js';
import camelCase from 'lodash.camelcase';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import MarkdownIt from 'markdown-it';
import memoize from 'lodash.memoize';
import queryString from 'query-string';
import scrollToWithAnimation from 'scrollto-with-animation';
import striptags from 'striptags';
import URL from 'url-parse';
import cookieUtils from './cookies';
import { parseSubfolderURL } from './consolidation';
import honeybadger from 'lib/errors/honeybadger';

const md = new MarkdownIt({
  html: true,
});

// helper methods for stickers
// "new" stickers are based on 30 days compared to doc's published date
const dayCount = 30;
const newStart = new Date(
  new Date().getTime() - dayCount * 24 * 60 * 60 * 1000,
);

const playlistCompareDate = new Date();
playlistCompareDate.setDate(playlistCompareDate.getDate() - 60);

// Helper method to create props req'd for a Sticker component
const sticker = (text, type = 'editorial') => ({
  text,
  type,
});

// navEvent triggers favorites ribbon refresh
let navEvent;
let historyEvent;
let resizeEvent;
let mixpanelUserConfiguredEvent;
let recentAuthEvent;
if (typeof window !== 'undefined') {
  navEvent = document.createEvent('Event');
  // 'jarvis-nav' is important - do not change
  // external utils use this event name to update the page when
  // in-app nav happens - so, this is any in-app change, not just jarvis
  navEvent.initEvent('jarvis-nav', true, true);

  resizeEvent = window.document.createEvent('UIEvents');
  resizeEvent.initUIEvent('resize', true, false, window, 0);

  mixpanelUserConfiguredEvent = document.createEvent('Event');
  mixpanelUserConfiguredEvent.initEvent('mixpanel:user:loaded', true, true);

  // custom event, fired after user logs in via xhr
  recentAuthEvent = document.createEvent('Event');
  recentAuthEvent.initEvent('recent-auth', true, true);

  // custom event, fired after window.onpopstate
  historyEvent = document.createEvent('Event');
  historyEvent.initEvent('history-event', true, true);
}

const utils = {
  // cheap type definition for later declared property
  debouncedTriggerNavEvent: () => null,
  duration(length) {
    const hours = Math.floor(length / 3600);
    const minutes = Math.floor(length / 60) % 60;
    const seconds = length % 60;
    return [
      hours >= 1 ? hours : null,
      hours >= 1 && minutes <= 9 ? `0${minutes}` : minutes,
      `${seconds > 0 && seconds <= 9 ? '0' : ''}${seconds}`,
    ]
      .filter((i) => i)
      .join(':');
  },
  hostSiteKeyMap: {
    americastestkitchen: 'atk',
    cookscountry: 'cco',
    cooksillustrated: 'cio',
    kids: 'kids',
    onlinecookingschool: 'school',
  },
  showIdShowNameMap: {
    atk: "America's Test Kitchen",
    cco: "Cook's Country",
    cio: "Cook's Illustrated",
    'perfectly-seasonal': 'Perfectly Seasonal',
    'whats-eating-dan': "What's Eating Dan",
    'gear-heads': 'Gear Heads',
  },
  siteKeyHostMap: {
    atk: 'americastestkitchen',
    cco: 'cookscountry',
    cio: 'cooksillustrated',
    kids: 'americastestkitchen',
    parents: 'americastestkitchen',
    school: 'onlinecookingschool',
  },
  siteKeySiteNameMap: {
    atk: "America's Test Kitchen",
    cco: "Cook's Country",
    cio: "Cook's Illustrated",
  },
  aspectRatioMap: {
    extraWide: '64:25',
    horizontal: '16:9',
    square: '1:1',
    vertical: '25:34',
  },
  addPrintToUrl(url) {
    const parsed = new URL(url);
    const glue = parsed.pathname.endsWith('/') ? '' : '/';
    parsed.set('pathname', `${parsed.pathname}${glue}print`);
    return parsed.href;
  },
  print() {
    if ('print' in window) {
      window.print();
    } else {
      alert(
        'Your device may require printing manually through the menu button.',
      );
    }
  },
  /* Cap the first letter, leave the rest as-is */
  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  cleanRouteParam(param) {
    return param.includes('?') ? param.split('?')[0] : param;
  },
  isWithinDateWindow(startTime, endTime) {
    if (!startTime || !endTime) return false;
    const now = new Date().getTime();
    return startTime <= now && now <= endTime;
  },
  focusableElements:
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
  dialogFocusHandler(evt, trigger, dialog) {
    const focusableContent = dialog.querySelectorAll(utils.focusableElements);
    const firstFocusable = focusableContent[0];
    const lastFocusable = focusableContent[focusableContent.length - 1];
    const isTabPressed = evt.key === 'Tab' || evt.keyCode === 9;
    const isEscapePressed = evt.keyCode === 27;
    const active = document.activeElement;
    const shift = evt.shiftKey;

    // IF shift key is pressed AND user is focusing on the first focusable child
    // or if user is focused on last focusable child, we close the dialogue
    if (
      isEscapePressed ||
      (isTabPressed &&
        ((shift && active === firstFocusable) || active === lastFocusable))
    ) {
      dry.events.publish('dialog:unfocus');
    }
  },
  emailRe:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  validateEmail(val) {
    return utils.emailRe.test(val);
  },
  passwordRe: /([^\s]){5}/,
  validatePassword(val) {
    return utils.passwordRe.test(val);
  },
  /** Specific Util for Large Tablets returning as Desktops */
  validateDeviceType(deviceType) {
    if (deviceType === 'desktop' && window.innerWidth < 1099) {
      return 'tablet';
    }
    return deviceType;
  },
  determineDeviceType(viewWidth) {
    let deviceType = 'mobile';
    if (viewWidth > 767) {
      deviceType = viewWidth > 991 ? 'desktop' : 'tablet';
    }
    return deviceType;
  },
  contentTypeToDocType: {
    TasteTest: 'taste_test',
    EquipmentReview: 'equipment_review',
  },
  docTypeToPathPrefix: {
    buying_guide: '',
    kids_recipe: 'kids',
    kids_activity: 'kids',
    kids_quiz: 'kids',
    article: '',
  },
  /** Used for building url paths (not necessarily via Router) */
  docTypeToPath: {
    'cms::page::article': 'articles',
    'cms::page::guide': 'guides',
    'cms::page': 'articles',
    'contentvertical::pageview': 'articles',
    'page::featurecontent': 'features',
    'Page::FeatureContent': 'features',
    'page::howto': 'how_tos',
    'Page::HowTo': 'how_tos',
    'page/how_to': 'how_tos',
    'review_set/equipment_review': 'equipment_reviews',
    'review_set/taste_test': 'equipment_reviews',
    'ReviewSet::EquipmentReview': 'equipment_reviews',
    'reviewset::equipmentreview': 'equipment_reviews',
    'ReviewSet::TasteTest': 'taste_tests',
    'reviewset::tastetest': 'taste_tests',
    'video::segment': 'videos',
    'video::fullepisode': 'videos',
    article: 'articles',
    barista_article: 'articles',
    cv_article: 'articles',
    book: '/books',
    buying_guide: 'buying-guides',
    buyingGuide: 'buying-guides',
    collection: 'collections',
    'Cms::Page::Article': 'articles',
    cvPageView: 'articles',
    cvpageview: 'articles',
    editorial_document_collection: 'collections',
    editorialdocumentcollection: 'collections',
    episode: 'episode',
    science: 'science',
    'Page::Science': 'science',
    'page::science': 'science',
    equipment_review: 'equipment_reviews',
    feature_content: 'features',
    guide: 'guides',
    how_to: 'how_tos',
    kids_activity: 'kids/activities',
    kids_quiz: 'kids/discover/quizzes',
    kids_recipe: 'kids/recipes',
    magazine: 'magazines',
    productcollection: 'buying-guides',
    playlist: 'skills',
    podcast: 'proof',
    product_collection: 'buying-guides',
    ProductCollection: 'buying-guides',
    recipe: 'recipes',
    Recipe: 'recipes',
    reviewable: 'equipment_reviews',
    taste_test: 'taste_tests',
    video: 'videos',
    page: 'how_tos',
  },
  /** Used for constructing router links */
  docTypeToCollectionType: {
    buyingGuide: 'buying-guides',
    buying_guide: 'buying-guides',
    equipment_review: 'equipment_reviews',
    how_to: 'how_tos',
    kids_recipe: 'recipes',
    kids_activity: 'activities',
    kids_quiz: 'quizzes',
    ProductCollection: 'buying-guides',
    'ReviewSet::EquipmentReview': 'equipment_reviews',
    'ReviewSet::TasteTest': 'taste-tests',
    taste_test: 'taste_tests',
    podcast: 'proof',
    recipe: 'recipes',
    episode: 'episodes',
    video: 'videos',
    article: 'articles',
  },
  docTypeFormatted: {
    article: 'Article',
    buying_guide: 'Buying Guide',
    cookbook: 'Cookbook',
    course: 'Course',
    episode: 'Episode',
    equipment_review: 'Equipment Review',
    how_to: 'How To',
    magazine: 'Magazine',
    recipe: 'Recipe',
    taste_test: 'Taste Test',
    video: 'Clip',
    'video::segment': 'Clip',
    'contentvertical::pageview': 'Article',
    'reviewset::equipmentreview': 'Equipment Review',
    'reviewset::taste_test': 'Taste Test',
    'page::howto': 'How To',
    productcollection: 'Buying Guide',
    'cms::page': 'Article',
    cv_article: 'Article',
    editorialdocumentcollection: 'Collection',
    collection: 'Collection',
  },
  resourceTypeTranslation: {
    'contentvertical::pageview': 'cv_article',
    cvPageView: 'cv_article',
    cvpageview: 'cv_article',
    'reviewset::equipmentreview': 'equipment_review',
    'reviewset::tastetest': 'taste_test',
    'reviewset::taste_test': 'taste_test',
    'page::featurecontent': 'feature',
    'page::howto': 'how_to',
    buyingGuide: 'buying_guide',
    playlist: 'skills',
    editorialdocumentcollection: 'collection',
    productcollection: 'buying_guide',
    'video::segment': 'video',
    'cms::page::guide': 'guide',
    'cms::page': 'article',
    'cms::page::article': 'article',
    recipe: 'recipe',
    episode: 'episode',
    video: 'video',
    article: 'article',
    taste_test: 'taste_test',
    buying_guide: 'buying_guide',
    equipment_review: 'equipment_review',
    feature_content: 'feature',
  },
  favoriteDocTypeToPath: {
    equipment_review: 'equipment_reviews',
    taste_test: 'taste_tests',
    feature: 'features',
    how_to: 'how_tos',
    buying_guide: 'buying-guides',
    skills: 'playlists',
    video: 'videos',
    guide: 'guides',
    article: 'articles',
    recipe: 'recipes',
    episode: 'episodes',
  },
  /** used to determine favoritable document types */
  collectionTypeToDocType: {
    articles: 'article',
    'buying-guides': 'buying_guide',
    buyingGuide: 'buying_guide',
    collection: 'collection',
    courses: 'course',
    episode: 'episode',
    episodes: 'episode',
    equipment_reviews: 'equipment_review',
    guides: 'guide',
    how_tos: 'how_to',
    proof: 'podcast',
    recipes: 'recipe',
    Recipe: 'recipes',
    taste_tests: 'taste_test',
    videos: 'video',
  },
  collectionTypeToAPIEndpoint: {
    proof: 'podcasts',
    episode: 'episodes',
    equipment_reviews: 'review_sets',
    taste_tests: 'review_sets',
  },
  docTypeToRoute: {
    kids_activity: 'prefixed-kids-detail-page',
    kids_recipe: 'prefixed-kids-detail-page',
    kids_quiz: 'prefixed-kids-detail-page-discover',
    article: 'prefixed-kids-detail-page',
  },
  cookies: cookieUtils,
  session: {
    create({ authToken, userToken, unsetAnon = true }) {
      cookieUtils.set('auth_token', authToken, { days: 30 });
      cookieUtils.set('user_token', userToken, { days: 30 });
      cookieUtils.set('recent_auth', true, { days: 1 });
      if (unsetAnon) cookieUtils.delete('anonymous');
      window.dispatchEvent(recentAuthEvent);
      if (typeof globalHeader !== 'undefined') {
        globalHeader.resetState();
      }
      if (typeof dry !== 'undefined') {
        dry.userService.update();
      }
    },
    destroy() {
      cookieUtils.delete('auth_token');
      cookieUtils.delete('user_token');
      cookieUtils.delete('recent_auth');
      cookieUtils.set('anonymous', true);
    },
  },
  formatInDollars(pennies) {
    pennies /= 100;
    return `$${pennies.toFixed(2)}`;
  },
  formatVisualStyle(visualStyle) {
    return camelCase(visualStyle);
  },
  getCurrentBreakpoint() {
    let breakpoint = 'phone';
    if (typeof window !== 'undefined') {
      const { innerWidth: width } = window;
      if (width >= 992) {
        breakpoint = 'desktop';
      } else if (width >= 768) {
        breakpoint = 'tablet';
      }
    }
    return breakpoint;
  },
  getDeviceType(req) {
    let ua;
    if (req && req.headers) {
      ua = req.headers['user-agent'];
    } else if (typeof window !== 'undefined') {
      ua = window.navigator.userAgent;
    }

    let deviceType = 'desktop';
    if (ua) {
      const parser = new UAParser(ua);
      const { type } = parser.getDevice();
      if (type === 'mobile') deviceType = 'phone';
      else if (type === 'tablet') deviceType = 'tablet';
    } else {
      deviceType = utils.getCurrentBreakpoint();
    }
    return deviceType;
  },
  getPathname(req) {
    const browserDoc = typeof document !== 'undefined' ? document : null;
    return req ? req.path : browserDoc ? browserDoc.location.pathname : null;
  },
  getReviewableAffiliateUrl(url, siteKey, location) {
    let prevAffiliateCode = `${siteKey}equippilot-20`;
    if (siteKey === 'atk') prevAffiliateCode = 'akoequippilot-20'; // old codes use ako instead of atk
    const nextAffiliateCode = `${siteKey}equip${location}-20`;
    return url.replace(prevAffiliateCode, nextAffiliateCode);
  },
  requireMetaPhoto(item) {
    let meta = null;
    try {
      meta = item.metaData || item.fields.metaData;
    } catch (err) {} // eslint-disable-line
    return (
      meta &&
      (typeof meta.photo !== 'undefined' ||
        typeof meta.fields.photo !== 'undefined')
    );
  },
  getDocumentDataset() {
    if (document) {
      const detailPage =
        document.querySelector('.detail-page-main') ||
        document.querySelector('.kids-content') ||
        document.querySelector('.reviewset-page-main');
      if (detailPage) return detailPage.dataset;
    }
    return {};
  },
  getDocumentPathname() {
    return (
      (typeof document !== 'undefined' && document.location.pathname) || null
    );
  },
  isForKidsAndFamiliesRecipe(doc) {
    const tags = doc.tags || doc;
    return !!tags.find(
      ({ fields }) => fields.tagTitle === 'For Kids & Families',
    );
  },
  getKidsLandingPageType() {
    const pathname = utils.getDocumentPathname();
    let page = '';
    if (pathname.includes('faq')) {
      page = 'faq';
    } else if (pathname.includes('how-it-works')) {
      page = 'how it works';
    } else if (pathname.includes('pcc-how-it-works')) {
      page = 'pcc how it works';
    } else if (pathname.includes('why-us')) {
      page = 'why us';
    } else if (pathname.includes('grownups')) {
      page = 'homepage';
    } else if (pathname.includes('join')) {
      page = 'step-0';
    } else if (pathname.includes('welcome')) {
      page = 'welcome';
    }
    return page;
  },
  getSiteDomain(siteKey, subdomain = 'www') {
    let domain = '';
    if (siteKey && subdomain) {
      domain = `https://${subdomain}.${
        this.siteKeyHostMap[siteKey.toLowerCase()]
      }.com`;
    }
    return domain;
  },
  getDocumentUrl(documentType, slug, siteKey, subdomain = 'www') {
    try {
      if (!slug) {
        if (documentType === 'RetailProduct') {
          // legacy transforms call without slug expecting to be returned '', where we
          //  later on give a url from ctaUrl data. Don't report these errors even though
          //  they shouldn't be calling this function with those arguments.
          return '';
        }
        honeybadger.notify(`getDocumentUrl slug not provided: ${slug}`, {
          context: {
            documentType,
            slug,
            siteKey,
            subdomain,
          },
        });
        return '';
      }
      // cast number to strings for following startsWith calls
      slug = slug?.toString() ?? slug;
      if (typeof slug !== 'string') return '';

      const domain = this.getSiteDomain(siteKey, subdomain);

      const slashSlug = slug.startsWith('/') ? slug : `/${slug}`;

      if (this.docTypeToPath[documentType] === undefined) {
        honeybadger.notify(
          `getDocumentUrl documentType not found: ${documentType}`,
          {
            context: {
              documentType,
              slug,
              siteKey,
              subdomain,
            },
          },
        );
      }

      // ?? documentType is wrong, but some urls passing bad document type might want
      //  slug only as url. Have todo for adding honeybadger here for bad calls.
      const typePath = this.docTypeToPath[documentType] ?? documentType;
      const slashTypePath = typePath?.startsWith('/')
        ? typePath
        : `/${typePath}`;

      // if slug = /collections/recipes-collections, we don't want to prepend typePath of /collections
      // if slug = /recipe-collections, we do want to prepend typePath of /collections
      // if slug = /kids/recipes/some-recipe-slug, we don't want to prepend typePath of recipe

      let finalSlug;
      if (slashSlug.includes(slashTypePath)) {
        finalSlug = slashSlug;
      } else {
        finalSlug = `${slashTypePath}${slashSlug}`;
      }

      if (documentType === 'magazine') finalSlug += '/browse';
      return `${domain}${finalSlug}`;
    } catch (error) {
      honeybadger.notify(error, 'getDocumentUrl error caught', {
        context: { documentType, slug },
      });
      return slug;
    }
  },
  getDocumentRelativePath(documentType, slug, siteKey) {
    if (!slug) return '';
    const path = this.docTypeToPath[documentType];
    let finalSlug =
      !slug.toString().startsWith(path) ||
      !slug.toString().startsWith(`/${path}`)
        ? `${path}/${slug}`
        : slug;
    if (documentType === 'magazine') finalSlug += '/browse';
    const linkUrl = `${!finalSlug.startsWith('/') ? '/' : ''}${finalSlug}`;
    return this.checkRelativeLink(siteKey, linkUrl);
  },
  getBaristaDocumentUrl(documentType, slug, siteKey) {
    return this.checkRelativeLink(
      siteKey,
      `/${this.docTypeToPath[documentType]}/${slug}`,
    );
  },
  getDocumentRoute(documentType) {
    return this.docTypeToRoute[documentType];
  },
  getDocumentParams(documentType, id) {
    return {
      collectionType: this.docTypeToCollectionType[documentType],
      id,
      pathPrefix: this.docTypeToPathPrefix[documentType],
    };
  },
  getComponent(obj, key) {
    return obj[key] || obj.default || null;
  },
  getElementById(id, returnDummy = false) {
    let el;
    const isBrowser = typeof document !== 'undefined';
    if (isBrowser) {
      el = document.getElementById(id);
    }
    return (
      el ||
      (returnDummy && isBrowser && document.createElement('div')) ||
      undefined
    );
  },
  getHeader(req, header) {
    return req?.headers[header] || req?.headers[header.toLowerCase()];
  },
  // Allow only letters, spaces, hyphen and numbers
  slugifyCollectionsName(str = '') {
    return str.replace(/[^a-zA-Z0-9|\s|-]/g, '');
  },
  getPageType() {
    const pathname = document.location.pathname;
    let pageType = '';
    if (pathname.includes('kids')) {
      if (pathname.includes('activities')) {
        pageType = 'kids_activities';
      } else if (pathname.includes('recipes')) {
        pageType = 'kids_recipes';
      } else if (pathname.includes('quizzes')) {
        pageType = 'kids_quizzes';
      }
    }
    return pageType;
  },
  getPictureShape: (media, opts = {}) => ({
    cloudinaryId: media.public_id || media.id,
    ...opts,
  }),
  getPosition: (el) => {
    const rect = el.getBoundingClientRect();
    const win = el.ownerDocument.defaultView;

    return {
      top: parseInt(rect.top + win.pageYOffset, 10),
      left: parseInt(rect.left + win.pageXOffset, 10),
    };
  },
  formatPublishDate(documentDate) {
    if (!documentDate) return '';
    const today = new Date(Date.now()).setHours(0, 0, 0, 0);
    // without adding zeros, the browser is off by 1 day b/c
    // it assumes a time zone which could cause diff btwn server/client
    // only add zeroes if dates do not already contain a time (dates from algolia contain time)
    const docDate =
      documentDate.length > 10
        ? documentDate
        : `${documentDate.replace(/-/g, '/')} 00:00:00`;
    const date = new Date(docDate);
    const month = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'June',
      'July',
      'Aug.',
      'Sept.',
      'Oct.',
      'Nov.',
      'Dec.',
    ][date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let publishDate = `${month} ${day}, ${year}`;
    if (today - date <= 43200000) {
      publishDate = 'Today';
    } else if (today - date <= 86400000) {
      publishDate = 'Yesterday';
    }
    return publishDate;
  },
  getQueryParams(req) {
    let queryParams = {};
    if (req) {
      queryParams = req.query;
    } else if (typeof document !== 'undefined') {
      const {
        location: { search },
      } = document;
      queryParams = queryString.parse(search);
    }
    return queryParams;
  },
  getQueryStringParam(param, req) {
    const obj = this.getQueryParams(req);
    return obj && typeof obj[param] !== 'undefined' ? obj[param] : null;
  },
  getReleaseDateString(releasedAt) {
    const releaseDate = new Date(releasedAt);
    return `${
      releaseDate.getMonth() + 1
    }.${releaseDate.getDate()}.${releaseDate.getFullYear()}`;
  },
  getRequestHostHeader(req) {
    let host;
    if (this.hasHeader(req, 'X-Proxied-For')) {
      host = this.getHeader(req, 'X-Proxied-For');
    } else if (this.hasHeader(req, 'host')) {
      host = this.getHeader(req, 'host');
    }
    return host;
  },
  getSeasonNumber(seasonTitle = '') {
    const [, seasonNumber] = seasonTitle.split('Season');
    return seasonNumber ? parseInt(seasonNumber.trim(), 10) : '';
  },
  getSubdomain() {
    return window.location.hostname.includes('localhost')
      ? 'https://www-staging'
      : window.location.hostname.split('.')[0];
  },
  getCookies: cookieUtils.getCookies,
  getDefaultUser() {
    return {
      activeMemberships: [],
      activeRegistrations: [],
      dfpMembershipString: 'anonymous',
      email: null,
      firstName: null,
      id: null,
      cancelledMemberships: [],
      packageName: null,
      name: null,
      role: 'guest',
      segment: 'anonymous',
    };
  },
  getUserSegment(segment) {
    if (segment.includes('cancelled')) {
      return 'former';
    }
    if (segment.includes('standard')) {
      return 'multisite';
    }
    return segment;
  },
  getUserData(callback) {
    const cookies = cookieUtils.getCookies();
    if (cookies.auth_token && typeof dry !== 'undefined') {
      try {
        dry.events.subscribe('user:get', (userData) => {
          callback(this.parseUserData(userData));
        });
      } catch (err) {
        callback(this.getDefaultUser());
        console.log('unable to subscribe to user events', err); // eslint-disable-line no-console
      }
    } else if (typeof callback === 'function') {
      callback(this.getDefaultUser());
    }
  },
  getZypeThumbnail(size, video) {
    // zype thumbnails are ordered from small to large
    // based on 'size', we pick the corresponding zype thumbnail
    const idx = {
      sm: 0,
      md: 1,
      lg: 2,
      xlg: 3,
    }[size];
    const thumbs = get(
      video,
      'thumbnails',
      get(video, 'metaData.thumbnails', []),
    );
    const thumbsCount = thumbs.length;
    const thumbIdx = Math.min(idx, thumbsCount);
    return thumbs?.[thumbIdx]?.url;
  },
  listTitleToGroupRelated(listTitle) {
    listTitle = listTitle.toLowerCase();
    const relatedSections = {
      "editor's picks": 'editors',
      'trending now': 'trending',
      'make this next': 'next',
      'keep browsing': 'tags',
    };
    return relatedSections[listTitle] || 'relateds';
  },
  loadScriptFile(url, async = true, defer = false, location = 'head') {
    if (!url) return;
    const gads = document.createElement('script');
    if (async) gads.async = async;
    if (defer) gads.defer = defer;
    gads.referrerPolicy = 'strict-origin';
    gads.type = 'text/javascript';
    const useSSL = document.location.protocol === 'https:';
    const protocol = useSSL ? 'https:' : 'http:';
    const {
      location: { hostname },
    } = document;
    const needsHostname = /.com|.net|.io/.test(url) === false;
    const startsWithSlashes = url.indexOf('//') === 0;
    gads.src = `${protocol}${startsWithSlashes ? '' : '//'}${
      needsHostname ? hostname : ''
    }${url}`;
    if (location === 'head') {
      const node = document.getElementsByTagName('script')[0];
      if (node) node.parentNode.insertBefore(gads, node);
    } else {
      document.body.appendChild(gads);
    }
  },
  loadCssFile(url) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen';
    link.href = url;

    const headScript = document.querySelector('script');
    headScript.parentNode.insertBefore(link, headScript);
  },
  siteKeyFromPathname(pathname) {
    if (pathname.startsWith('/cookscountry')) return 'cco';
    if (pathname.startsWith('/cooksillustrated')) return 'cio';
    return 'atk';
  },
  isAuthenticated(req) {
    const { auth_token: authToken } = cookieUtils.getCookies({ req });
    return typeof authToken !== 'undefined';
  },
  isNewAuthenticated(user) {
    // New authentication check using the user that is set in the App
    // from the middleware
    return user.segment !== 'anonymous';
  },
  isPlayUrl(req) {
    const { path } = req;
    return (
      path.includes('/episode') ||
      path.includes('/podcasts') ||
      path.includes('/shows') ||
      path.includes('/skills') ||
      path.includes('/whats-eating-dan') ||
      path.includes('/gear-heads') ||
      path.includes('/perfectly-seasonal') ||
      path.includes('/proof') ||
      path.includes('/mystery-recipe') ||
      path.includes('/the-walk-in') ||
      path.includes('/videos')
    );
  },
  isFavoritesUrl(req) {
    const { path } = req;
    return (
      path.includes('/favorites') || path.includes('/favorite_collections')
    );
  },
  isPlaylistNew({ publishDate }) {
    return publishDate ? new Date(publishDate) > playlistCompareDate : false;
  },
  /**
   * @returns {import('./summary-document-transforms').Sticker[]}
   */
  getStickers(documentDate, documentStickers, documentKlass, siteKey) {
    const stickers = [];
    if (documentDate) {
      const isNew = new Date(documentDate) >= newStart;
      // is the document itself new?
      if (isNew) stickers.push(sticker('new', 'priority'));
    }

    const isKidsArticle = documentKlass === 'article' && siteKey === 'kids';
    if (
      documentStickers &&
      documentStickers.length > 0 &&
      (documentKlass !== 'article' || isKidsArticle)
    ) {
      // don't display editorial stickers on non-kids articles in serp
      // 'documentStickers' are the editorial stickers identified at indexing time
      stickers.push(...documentStickers.filter(Boolean).map((s) => sticker(s)));
    }
    return stickers.length > 2 ? stickers.slice(0, 2) : stickers;
  },
  requestToSiteKey(req, host) {
    let siteKey = 'atk';
    try {
      if (req) {
        host = host || this.getRequestHostHeader(req) || '';
        const bits = host.split('.');
        siteKey = (bits.length && this.hostSiteKeyMap[bits[1]]) || 'atk';
        if (req.path.startsWith('/cookscountry')) {
          siteKey = 'cco';
        } else if (req.path.startsWith('/cooksillustrated')) {
          siteKey = 'cio';
        } else if (req.path.startsWith('/kids')) {
          siteKey = 'kids';
        }
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('Unable to convert request to site key');
    }
    return siteKey;
  },
  checkRelativeLink(siteKey, link) {
    if (link.startsWith('/cookscountry')) return link;
    if (link.startsWith('/cooksillustrated')) return link;

    if (link.startsWith('/')) {
      let subdirectory = '';
      switch (siteKey) {
        case 'cco':
          subdirectory = '/cookscountry';
          break;
        case 'cio':
          subdirectory = '/cooksillustrated';
          break;
        default:
          break;
      }

      return `${subdirectory}${link}`;
    }

    return link;
  },
  requestToSubdomain(req) {
    const host = this.getRequestHostHeader(req);
    let subdomain = 'www';
    if (host.indexOf('www') === 0) {
      subdomain = host.split('.').shift();
    }
    return subdomain;
  },
  requestToUrl(req) {
    const browserDoc = typeof document !== 'undefined' ? document : null;
    return (
      (req &&
        `${req.protocol}://${utils.getRequestHostHeader(req)}${
          req.originalUrl
        }`) ||
      (browserDoc && browserDoc.location.href) ||
      ''
    );
  },
  /**
   * Comparator which can be used in shouldComponentUpdate methods.
   * NOTE: Immutable .equals() should be preferred
   */
  shallowEquals(objA, objB) {
    if (objA === objB) {
      return true;
    }

    if (
      typeof objA !== 'object' ||
      objA === null ||
      typeof objB !== 'object' ||
      objB === null
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    // Test for A's keys different from B.
    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
    for (let i = 0; i < keysA.length; i += 1) {
      if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }

    return true;
  },
  hasHeader(req, header) {
    return (
      this.isDefined(req?.headers[header]) ||
      this.isDefined(req?.headers[header.toLowerCase()])
    );
  },
  innerHTML(id) {
    const el = this.getElementById(id);
    return (el && el.innerHTML) || '';
  },
  isDefined(val) {
    return typeof val !== 'undefined';
  },
  scrollToEl(selector, topMargin = 40, duration = 250) {
    let el = selector;
    if (typeof el === 'string') {
      el = document.querySelector(selector);
    }
    if (el) {
      let pos = utils.getPosition(el).top - topMargin;
      if (pos < 100) pos = 0;
      utils.scrollTo(pos, duration);
    }
  },
  scrollTo(yPos, duration = 250) {
    // browser compatibility - some use documentElement, some body
    [document.documentElement, document.body].forEach((el) => {
      scrollToWithAnimation(el, 'scrollTop', yPos, duration, 'easeInOutCirc');
    });
  },
  shuffleArray(arr) {
    arr.sort(() => Math.random() - 0.5);
  },
  siteKeyToHost(siteKey) {
    return (siteKey && this.siteKeyHostMap[siteKey]) || null;
  },
  siteKeyToRibbonColor(siteKey) {
    return (
      {
        atk: '#3D3D3D',
        cco: '#000000',
        cio: '#3f2b1e',
      }[siteKey] || '#3D3D3D'
    );
  },
  ssoLink(url, subdomain = 'www') {
    const domain = `https://${subdomain}.americastestkitchen.com`;
    return `${domain}/sso/relay?target=${encodeURIComponent(url)}`;
  },
  hostToSiteKey(host) {
    return (host && this.hostSiteKeyMap[host]) || null;
  },
  /**
   * Should be used for atk|cio|cco links only
   * pull the `americastestkitchen` part out of
   * www.americastestkitchen.com or www-test.americastestkitchen.com, etc
   * @param  {String} hostname fully qualified domain
   * @return {String}          domain name without the `.com`
   */
  hostnameToDomain(hostname) {
    return hostname.replace(/([\w-]+).([\w]+).(com)/g, '$2');
  },
  /**
   * Should be used for atk|cio|cco links only
   * maps hostname to sitekey. Used for pre domain consolidation links
   * www.americastestkitchen.com or www-test.americastestkitchen.com, to atk
   * @param  {String} hostname fully qualified domain
   * @returns {'atk'|'cio'|'cco'}
   */
  hostnameToSiteKey(hostname) {
    return this.hostToSiteKey(this.hostnameToDomain(hostname));
  },
  getDocSiteKey(doc, domainSiteKey) {
    let { metaData: { siteList = [] } = {} } = doc;
    if (!siteList?.length && doc.fields) {
      siteList = doc.fields?.metaData?.fields?.siteList ?? [];
    }
    const key =
      siteList.includes(domainSiteKey) ||
      siteList.includes(domainSiteKey.toUpperCase())
        ? domainSiteKey
        : siteList[0];
    return key ? key.toLowerCase() : domainSiteKey;
  },
  /**
   * Based on url, extract document type and slug
   * @param  {String} url                    Document URL
   */
  getDocInfoFromUrl(url = '') {
    if (!url) return null;
    let docType;
    let docSlug;
    if (url.includes('shop.')) {
      docType = 'cookbook';
      docSlug = url;
    } else {
      // parse url to determine docType and docSlug
      const { pathname } = new URL(url);
      // remove leading slash from `/recipes/8125-cookies` and split
      [docType, docSlug] = pathname.substring(1).split('/');
    }
    return { docType, docSlug };
  },
  /**
   * CV articles cannot be favorited. Anything that is not properly formatted
   * or below the ENV threshold will be 'unfavoritable'
   * @param {String} docSlug `8125-cookies-are-awesome`
   * @returns Boolean
   */
  isCvGuide(docType, docSlug) {
    if (docType !== 'guides') return false;
    // Most old guides don't have ids in the slug, but some start with numbers.
    //  so we use a cut-off of 5000 like we had for barista articles
    return /^([0-9]{1,3}|[1-4][0-9]{3})(?![0-9])/.test(docSlug);
  },
  /**
   * Based on a url, extract the data required for favoriting
   * @param  {String} url                    Document URL
   * @param  {String} [defaultSiteKey='atk'] Fallback siteKey
   * @return {{ favoritableId?: string, favoritableSiteKey?: string }}                        Favoritable data
   */
  getFavoritableDataFromUrl(url, defaultSiteKey = 'atk') {
    if (!url) return {};
    const { pathname, siteKey } = parseSubfolderURL(url);
    const [docType, docSlug] = pathname.substring(1).split('/');
    const canFavorite =
      docType &&
      docSlug &&
      this.collectionTypeToDocType[docType] &&
      this.isCvGuide(docType, docSlug) === false;

    if (!canFavorite) return {};

    const favoritableId = `${this.collectionTypeToDocType[docType]}_${docSlug
      .split('-')
      .shift()}`;
    const favoritableSiteKey = siteKey ?? defaultSiteKey;
    return { favoritableId, favoritableSiteKey };
  },
  getFavoritableDataFromDocument(
    item,
    siteKey,
    subdomain,
    isAuthenticated = false,
  ) {
    const { componentType, documentType, fields = {} } = item;

    let itemType = documentType || componentType;
    if (!itemType) itemType = fields.documentType || fields.componentType;
    const metaData =
      get(item, 'fields.metaData.fields', item.metaData, {}) || {};
    const url = fields.url || null;
    const slug = fields.slug || item.slug;
    const siteList = (metaData && metaData.siteList) || null;

    let favoritableId;
    let favoritableSiteKey = siteKey;

    if (siteList) {
      favoritableSiteKey =
        siteList && siteList.includes(siteKey.toUpperCase())
          ? siteKey
          : siteList[0];
    }
    const notFavoritable = ['editorialDocumentCollection'];

    if (isAuthenticated && slug && !notFavoritable.includes(itemType)) {
      const isEverest = /([\d]+)-/.test(slug);
      const documentId = isEverest ? slug.split('-').shift() : item.id;
      itemType = this.resourceTypeTranslation[itemType] || itemType;
      favoritableId = `${itemType}_${documentId}`;
      if (url) {
        // extract the object_id and siteKey from the url
        // falling back to current siteKey
        const favData = this.getFavoritableDataFromUrl(url, siteKey);
        favoritableId = favData.favoritableId;
        favoritableSiteKey = favData.favoritableSiteKey;
      }
    }
    favoritableSiteKey = favoritableSiteKey
      ? favoritableSiteKey.toLowerCase()
      : siteKey;
    return { favoritableId, favoritableSiteKey };
  },
  isLandingPages({ path }) {
    return [
      'grownups',
      'how-it-works',
      'join',
      'pcc-join',
      'faq',
      'why-us',
      'welcome',
      'welcome/confirmation',
    ].includes(path.replace('/kids/', ''));
  },
  isInSite(url) {
    const atkDomains = [
      'americastestkitchen',
      'cookscountry',
      'cooksillustrated',
    ];
    return atkDomains.some((domain) => url.includes(domain));
  },
  mixpanel: {
    formatProperties: (properties) => {
      const formattedKeys = {};
      Object.keys(properties).forEach((property) => {
        formattedKeys[utils.strings.camelToUnderscore(property)] =
          properties[property];
      });
      return formattedKeys;
    },
  },
  parseUserData(userData) {
    const {
      currentUser: { email, firstName, fullName: name, id },
      currentUserAccess: {
        cancelledSiteMemberships: cancelledMemberships = [],
        dfpMembershipString: dfpString,
        package: packageName,
        registrationSiteKeys: activeRegistrations = [],
        role,
        segment,
        activeCDSMembership,
        siteMemberships: activeMemberships = [],
      },
    } = userData;
    const dfpEmpty = Boolean(dfpString) === false;
    let dfpMembershipString = dfpString;
    if (dfpEmpty) {
      dfpMembershipString = 'anonymous';
      if (
        activeMemberships.length >= 3 &&
        ['atk', 'cio', 'cco'].filter((value) =>
          activeMemberships.includes(value),
        ).length === 3
      ) {
        dfpMembershipString = 'all_access';
      } else if (activeMemberships.includes('cookbook_collection')) {
        dfpMembershipString = 'premium';
      } else if (activeMemberships.length >= 1) {
        dfpMembershipString = 'basic';
      } else if (activeRegistrations.length > 0) {
        dfpMembershipString = 'registrant';
        if (cancelledMemberships.length > 0) {
          dfpMembershipString = 'renewal';
        }
      }
    }

    const modifiedSegment = segment.includes('standard')
      ? 'multisite'
      : segment;

    const modifiedDFP =
      {
        premium: 'all_access',
        basic: 'all_access',
      }[dfpMembershipString] ?? dfpMembershipString;

    return {
      activeCDSMembership,
      activeMemberships,
      activeRegistrations,
      dfpMembershipString: modifiedDFP,
      email,
      firstName,
      id,
      cancelledMemberships,
      packageName,
      name,
      role,
      segment: modifiedSegment,
    };
  },
  /**
   * Get window query string as an object.
   */
  getQueryStringObj(qs = this.getWindowQueryString()) {
    return queryString.parse(qs);
  },
  /**
   * Get query string from window, if it exists
   */
  getWindowQueryString() {
    let qs = '';
    if (typeof window !== 'undefined') {
      qs = window.location.search.replace(/^\?/, '');
    }
    return qs;
  },
  strings: {
    camelToUnderscore(str) {
      return str
        .replace(/([A-Z])/g, ' $1')
        .split(' ')
        .join('_')
        .toLowerCase();
    },
    markdown(str) {
      return md.render(striptags(str, ['a', 'br', 'b', 'strong', 'i', 'em']));
    },
    stripTags(str, allowedTags) {
      return striptags(str, allowedTags);
    },
  },
  toggleFixedBackground(isOpen) {
    if (!isOpen) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('width');
    }
  },
  triggerNavEvent() {
    if (typeof navEvent !== 'undefined') {
      window.dispatchEvent(navEvent);
    }
  },
  triggerHistoryEvent() {
    if (typeof historyEvent !== 'undefined') {
      window.dispatchEvent(historyEvent);
    }
  },
  triggerResizeEvent() {
    if (typeof resizeEvent !== 'undefined') {
      setTimeout(() => window.dispatchEvent(resizeEvent), 0);
    }
  },
  truncateString(value, maxLength, suffix = '…') {
    return value && value.length > maxLength
      ? `${value.substring(0, maxLength)}${suffix}`
      : value;
  },
  scroll: {
    disable() {
      const $body = document.querySelector('body');
      const scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      const preventTouch = (evt) => {
        if (evt.preventDefault) evt.preventDefault();
        return false;
      };
      document.addEventListener('touchmove', preventTouch);

      return () => {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
        document.removeEventListener('touchmove', preventTouch);
      };
    },
  },
  accessibleCarouselScroll(identifier) {
    const focusScrollCarousel = (carousel) => {
      const nextButton = carousel.getElementsByClassName('next')[0];
      if (nextButton) {
        nextButton.click();
      }
    };

    if (identifier && identifier !== 'reviews-castMembers') {
      const currentCarousel = document.getElementById(identifier);
      if (currentCarousel) {
        currentCarousel.addEventListener('focusin', (ev) => {
          const carouselElement = ev.target.closest('.carousel-cell');
          if (carouselElement) {
            const classes = carouselElement.classList;
            if (!classes.contains('is-selected')) {
              focusScrollCarousel(currentCarousel);
            }
          }
        });
      }
    }
  },
  /** Format Dates for Schema Seo */
  seoDataFormat(dateTime) {
    const date = new Date(dateTime).toISOString();
    return date;
  },
};

utils.debouncedTriggerNavEvent = debounce(utils.triggerNavEvent, 250);
utils.parseUserToken = memoize(utils.parseUserData);

export default utils;

/* eslint-disable */
/* Polyfill for .closest() method - https://github.com/jonathantneal/closest */
if (typeof window !== 'undefined') {
  (function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
      ElementProto.matches =
        ElementProto.msMatchesSelector ||
        ElementProto.mozMatchesSelector ||
        ElementProto.webkitMatchesSelector ||
        function matches(selector) {
          var element = this;
          var elements = (
            element.document || element.ownerDocument
          ).querySelectorAll(selector);
          var index = 0;

          while (elements[index] && elements[index] !== element) {
            ++index;
          }

          return Boolean(elements[index]);
        };
    }

    if (typeof ElementProto.closest !== 'function') {
      ElementProto.closest = function closest(selector) {
        var element = this;

        while (element && element.nodeType === 1) {
          if (element.matches(selector)) {
            return element;
          }

          element = element.parentNode;
        }

        return null;
      };
    }
  })(window.Element.prototype);
  if (
    typeof window.NodeList !== 'undefined' &&
    typeof NodeList.prototype.forEach === 'undefined'
  ) {
    window.NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
}
/* eslint-enable */

export function getSiteCapitalized() {
  if (typeof window === 'undefined') return 'AmericasTestKitchen.com';

  const host = utils.hostnameToDomain(window.location.hostname);
  const siteKey = utils.hostToSiteKey(host);
  return (
    {
      atk: 'AmericasTestKitchen.com',
      cco: 'CooksCountry.com',
      cio: 'CooksIllustrated.com',
    }[siteKey] ?? 'AmericasTestKitchen.com'
  );
}

export * from './search-transforms';
