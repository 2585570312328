type Endpoints = 'atk' | 'cv' | 'v4' | 'v5' | 'v6' | 'v7' | 'v8' | 'comments';

/** e.g. https://www.americastestkitchen.com (no /api/v6) */
const mainEndpoint = process.env.NEXT_PUBLIC_ATK_API_DOMAIN;
/** TODO: Fix this (legacy API_ATK_URL probably meant for atk-node) */
const atkEndpoint = process.env.NEXT_PUBLIC_ATK_API_ATK ?? mainEndpoint;
const cvEndpoint = process.env.NEXT_PUBLIC_ATK_API_CV ?? mainEndpoint;
const v4Endpoint = process.env.NEXT_PUBLIC_ATK_API_V4 ?? mainEndpoint;
const v5Endpoint = process.env.NEXT_PUBLIC_ATK_API_V5 ?? mainEndpoint;
const v6Endpoint = process.env.NEXT_PUBLIC_ATK_API_V6 ?? mainEndpoint;
const v7Endpoint = process.env.NEXT_PUBLIC_ATK_API_V7 ?? mainEndpoint;
const v8Endpoint = process.env.NEXT_PUBLIC_ATK_API_V8 ?? mainEndpoint;
/** Only override required atm (https://speak-easy-prod.herokuapp.com & https://www-test.americastestkitchen.com/spe-api) */
const speakeasyEndopint =
  process.env.NEXT_PUBLIC_ATK_API_SPEAKEASY ?? mainEndpoint;

const endpoints: Record<string, string> = {
  atk: `${atkEndpoint}`,
  cv: `${cvEndpoint}/guides/api/v1/`, // cv has trailing slash in current prod env
  v4: `${v4Endpoint}/api/v4`,
  v5: `${v5Endpoint}/api/v5`,
  v6: `${v6Endpoint}/api/v6`,
  v7: `${v7Endpoint}/api/v7`,
  v8: `${v8Endpoint}/api/v8`,
  comments: `${speakeasyEndopint}`,
};

export function getEndpoint(endpoint: Endpoints): string;
export function getEndpoint(endpoint: string): string | undefined;

/**
 * Supports getUrlBase in atk.js and can be used directly.
 * Applies endpoint specific prefixes to a default domain `NEXT_PUBLIC_ATK_API_DOMAIN`.
 * Can override individual endpoints for local development.
 * Only comments (speakeasy) currently requires an override because it is not served through nginx.
 *
 * - atk: NEXT_PUBLIC_ATK_API_ATK (as is)
 * - cv: NEXT_PUBLIC_ATK_API_CV + /guides/api/v1/
 * - v4: NEXT_PUBLIC_ATK_API_V4 + /api/v4
 * - v5: NEXT_PUBLIC_ATK_API_V5 + /api/v5
 * - v6: NEXT_PUBLIC_ATK_API_V6 + /api/v6
 * - v7: NEXT_PUBLIC_ATK_API_V7 + /api/v7
 * - v8: NEXT_PUBLIC_ATK_API_V8 + /api/v8
 * - comments: NEXT_PUBLIC_ATK_API_SPEAKEASY (as is)
 */
export function getEndpoint(endpoint: Endpoints | string): string | undefined {
  return endpoints[endpoint.toLowerCase()];
}
