import App, { AppInitialProps } from 'next/app';
import { wrapper } from 'lib/store/store';
import React from 'react';
import { AppProvider } from 'lib/hooks/useAppContext';
import ErrorBoundary from 'components/boundaries/ErrorBoundary';
import LoadPianoSdk from 'components/integrations/PianoSDK/LoadPianoSDK';
import GlobalStyle from 'components/globalstyles';
import GlobalLayout from 'components/layouts/GlobalLayout/GlobalLayout';
import {
  MixpanelProvider,
  properties,
} from 'lib/hooks/piano/usePaywallMixpanelContext';
import { SWRConfig } from 'swr';
import '@americastestkitchen/mise/styles.css';
import 'mise/src/styles/styles.scss';
import '@americastestkitchen/ui/styles.css';
import { SET_ORIGIN } from 'actions/origin';
import utils from 'lib/utils';
import {
  domainSiteKeyFromPath,
  isPlayPagePath,
  siteKeyFromPath,
} from './_document';
import { getCookie } from 'cookies-next';
import {
  favsOnReady,
  runFavorites,
} from 'components/integrations/FavoritesWidget/favorites';
import {
  newsletterOnReady,
  runNewsletterWidget,
} from 'components/integrations/NewsletterWidget/newsletter-widget';
import { SvgProvider } from '@americastestkitchen/mise/components/Icons/SvgProvider/SvgProvider';
import dynamic from 'next/dynamic';
import { DisableTrackingProvider } from 'components/providers/DisableTrackingProvider';
import subdomainFromNextReq from 'utils/subdomainFromNextReq';
import absoluteUrlFromInitialProps from 'utils/absoluteUrlFromInitialProps';
import { FF_V6_LOGIN } from 'server/lib/feature-flags';

const PianoPromotionalRibbon = dynamic(
  () => import('components/integrations/PianoPlacements/PromotionalRibbon'),
  {
    ssr: false,
  },
);

class MyApp extends App<AppInitialProps> {
  public static getInitialProps = wrapper.getInitialAppProps(
    (store) => async (context) => {
      const { ctx, router } = context;
      const siteKey = siteKeyFromPath(router.asPath);
      const domainSiteKey = domainSiteKeyFromPath(router.asPath);
      const isHomepage = ctx.req?.headers['x-referer'] === 'homepage';
      const referrer = isHomepage ? 'homepage' : siteKey;
      const authToken = getCookie('auth_token', { req: ctx.req, res: ctx.res });
      const subdomain = subdomainFromNextReq(ctx.req);
      const url = absoluteUrlFromInitialProps(ctx.req);
      const pathname = context.ctx.asPath
        ? context.ctx.asPath.split(/[?#]/)[0]
        : '';
      const deviceType = utils.getDeviceType(ctx.req);
      const isPlay = isPlayPagePath(router.asPath);
      let user = null;

      if (FF_V6_LOGIN && ctx.res) {
        user = JSON.parse(ctx.res.getHeader('user') as string);
      }

      const userToken = getCookie('user_token', { req: ctx.req, res: ctx.res });

      const isAuthenticated = FF_V6_LOGIN
        ? utils.isNewAuthenticated(user)
        : typeof authToken !== 'undefined' && authToken.length > 0;

      // Dispatch some redux state various components still use
      store.dispatch({
        type: SET_ORIGIN,
        payload: { siteKey, subdomain, isAuthenticated, referrer },
      });
      return {
        pageProps: {
          ...(await App.getInitialProps(context)).pageProps,
          pathname,
          queryParams: router.query,
          isAuthenticated,
          subdomain,
          referrer,
          deviceType,
          disableTracking: false,
          domainSiteKey,
          siteKey,
          isPlay,
          url,
          userToken,
        },
      };
    },
  );

  componentDidMount() {
    // TODO: is this needed?
    // window.onpopstate = utils.triggerHistoryEvent;
    favsOnReady(runFavorites);
    newsletterOnReady(runNewsletterWidget);
  }

  render() {
    const { Component, pageProps } = this.props;
    const {
      deviceType,
      domainSiteKey,
      disableTracking,
      isAuthenticated,
      queryParams,
      url,
      pathname,
      subdomain,
      isPlay,
      userToken,
    } = pageProps;

    return (
      <AppProvider
        value={{
          deviceType,
          domainSiteKey,
          disableTracking,
          isAuthenticated,
          pageProps,
          queryParams,
          url,
          pathname,
          subdomain,
          serversideUserToken: userToken,
        }}
      >
        <ErrorBoundary>
          <DisableTrackingProvider disableTracking={disableTracking}>
            <SWRConfig
              value={{
                dedupingInterval: 360000,
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
              }}
            >
              <GlobalStyle />
              <LoadPianoSdk />
              <PianoPromotionalRibbon />
              <SvgProvider />
              <GlobalLayout brandKey={domainSiteKey} isPlay={isPlay}>
                <MixpanelProvider value={properties}>
                  <Component {...pageProps} />
                </MixpanelProvider>
              </GlobalLayout>
            </SWRConfig>
          </DisableTrackingProvider>
        </ErrorBoundary>
      </AppProvider>
    );
  }
}

export default wrapper.withRedux(MyApp);
