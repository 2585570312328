import utils from '../utils';
import constants from '../../config/constants';

const { TRACKING_VENDORS: vendors } = constants;

const trackingClasses = [];

/* START - GENERAL EVENTS */
const notifyEvent = (...args) => {
  trackingClasses.forEach((tracker) => {
    if (typeof tracker.trackEvent === 'function') {
      tracker.trackEvent(...args);
    }
  });
};

const trackEvent = (...args) => {
  notifyEvent(...args);
};

/* END - GENERAL EVENTS */

/* START - RESET USER EVENTS */
const notifyRefreshUser = (user) => {
  trackingClasses.forEach((tracker) => {
    if (typeof tracker.refreshUser === 'function') {
      tracker.refreshUser(user);
    }
  });
};

const refreshUser = (...args) => {
  notifyRefreshUser(...args);
};
/* END - PAGE EVENTS */

/* START - SETUP CODE */
const handleDocumentClick = (e) => {
  const eventEl = e.target.closest('[data-event]');
  if (!eventEl || !eventEl.dataset) return;
  const eventName = eventEl && eventEl.dataset.event;

  let eventData = (eventEl && eventEl.dataset.eventParams) || null;
  if (eventData) {
    try {
      eventData = JSON.parse(eventData);
    } catch (err) { }; // eslint-disable-line
  }
  if (eventName) {
    trackEvent(eventName, eventData);
  }
};

const addTracker = (tracker, ...params) => {
  const TrackerClass = require('./' + tracker.toLowerCase() + '/index').default; // eslint-disable-line
  return TrackerClass && trackingClasses.push(new TrackerClass(...params));
};

const initialize = (user) => {
  if (typeof window === 'undefined') return;
  const siteKey = utils.siteKeyFromPathname(window.location.pathname);
  const vendorList = vendors[siteKey] || vendors.default;
  const cookies = utils.getCookies();
  vendorList.forEach((tracker) =>
    addTracker(tracker, user, location, cookies, siteKey),
  );

  if (window.trackingEventData !== undefined) {
    trackEvent(window.trackingEventData);
  }
  document.addEventListener('click', handleDocumentClick);
};

/** IIFE to kick things off */
(() => {
  const { auth_token: authToken } = utils.getCookies();
  if (authToken) {
    utils.getUserData(initialize);
  } else {
    initialize();
  }
})();

/* END - SETUP CODE */

const manager = {
  initialize,
  refreshUser,
  trackEvent,
};

export default manager;
