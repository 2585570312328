import {
  EMAIL_FRIEND_PENDING,
  EMAIL_FRIEND_FULFILLED,
  EMAIL_FRIEND_REJECTED,
  EMAIL_FRIEND_RESET,
} from '../actions/emailFriend';
import analytics from '../lib/analytics';
import constants from '../config/constants';
import utils from '../lib/utils';

const { STATUS_CODES: c } = constants;

export const initialState = {
  message: null,
  status: c.DEFAULT,
};

export const errorMessage =
  'Oops, something bad has happened. Please try again later.';
export const successMessage = 'Email sent successfully!';

const emailFriend = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_FRIEND_RESET:
      return {
        ...initialState,
      };
    case EMAIL_FRIEND_PENDING:
      return {
        message: null,
        status: c.LOADING,
      };

    case EMAIL_FRIEND_REJECTED:
      return {
        message: errorMessage,
        status: c.ERROR,
      };

    case EMAIL_FRIEND_FULFILLED:
      const {
        data: { success },
      } = action.payload;
      let retval;
      if (success) {
        retval = {
          message: successMessage,
          status: c.READY,
        };
        const { documentId, documentTitle, documentType } =
          utils.getDocumentDataset();
        if (documentId) {
          analytics.track(
            'EMAIL_FRIEND',
            utils.mixpanel.formatProperties({
              documentId,
              documentTitle,
              documentType,
            }),
          );
        }
      } else {
        retval = {
          message: errorMessage,
          status: c.ERROR,
        };
      }
      return retval;

    default:
      return state;
  }
};

export default emailFriend;
