import Honeybadger from '@honeybadger-io/js';

const disable = process.env.NEXT_PUBLIC_HONEYBADGER_DISABLE === 'true';

function regexMatches(target: string, ...regexes: RegExp[]): boolean {
  for (const regex of regexes) {
    if (regex.test(target)) {
      return true;
    }
  }
  return false;
}

const honeybadger = Honeybadger.configure({
  developmentEnvironments: ['development', 'dev', 'staging'],
  environment: process.env.ENVIRONMENT_NAME || 'development',
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  revision: process.env.NEXT_PUBLIC_SOURCE_VERSION || 'localhost',
  projectRoot: 'webpack://_N_E/./',
  // keep low for initial release
  //  don't need reset right now because of all the non SPA routing
  maxErrors: 2,
  // disable intially for zype non-reported errors
  enableUnhandledRejection: false,
  // set NEXT_PUBLIC_HONEYBADGER_DISABLE to 'true' to disable
  ...(disable && { reportData: false }),
});

honeybadger.beforeNotify((notice) => {
  // disable server notifications
  if (typeof window === 'undefined') return false;

  // ignore errors without any stack
  if (!notice?.backtrace?.length) return false;

  const backtraceFileMatch = regexMatches(
    notice?.backtrace?.[0]?.file ?? '',
    /^chrome-extension/,
    /^moz-extension/,
    /^safari-extension/,
    /^<anonymous>/,
    /^unknown/,
    /^https:\/\/assets\.bounceexchange\.com/,
    /^https:\/\/dev\.visualwebsiteoptimizer\.com/,
    /^https:\/\/d1nfaf380zbiur\.cloudfront\.net\/_search_assets/,
    /^https:\/\/d1nfaf380zbiur\.cloudfront\.net\/assets/,
  );

  if (backtraceFileMatch) return false;

  const messageMatch = regexMatches(
    notice?.message ?? '',
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#50387233
    /^ResizeObserver loop limit exceeded/,
    /^ResizeObserver loop completed with undelivered notifications/,
    // Instagram/Facebook webview external functions
    /^Can't find variable: _AutofillCallbackHandler/,
    /^Can't find variable: _pcmBridgeCallbackHandler/,
    // Jarvis errors without sourcemap
    /^Server did not respond in a timely manner/,
    /^Cannot read properties of undefined \(reading 'search'\)/,
    /^Cannot read property 'search' of undefined/,
    /^Unexpected network error occurred/,
    /^this.index is undefined/,
    /^Not authorized/,
    // Facebook widgets from cv outside of scope
    /^invalid version specified/,
    // https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
    /^TypeError: undefined is not an object \(evaluating '__gCrWeb/,
    // https://github.com/getsentry/sentry/issues/61469
    /^null is not an object \(evaluating 'Object.prototype.hasOwnProperty.call\(o,“telephone”\)'\)/,
    // Paprika browser extension
    /^Can't find variable: Paprika/,
    // Eval, 3rd party script, extension, or malware we can't track or distinguish
    /^Unexpected token/,
    /^Unexpected end of input/,
    /^Unexpected identifier/,
  );

  // ignored messages https://docs.honeybadger.io/lib/javascript/guides/reducing-noise/
  if (messageMatch) return false;

  const stackMatch = regexMatches(
    notice?.stack ?? '',
    // Paprika browser extension
    /^global code@/,
    /^Paprika/,
  );

  if (stackMatch) return false;

  // only users with auth_token set get reporting
  return /\bauth_token=/.test(document.cookie);
});

export default honeybadger;
