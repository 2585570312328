import Script from 'next/script';

const LoadPianoSdk = () => {
  const pianoAdblock = process.env.PIANO_ADBLOCKER_ENABLED;
  const pianoConfig = `
    tp = window.tp || [];
    tp.push(["setUseTinypassAccounts", false]);
    tp.push(["setUsePianoIdUserProvider", false ]);
    tp.push(["setUsePianoIdLiteUserProvider", true ]);
    const isProd = document.location.hostname.indexOf('www-') === -1;
    if (!isProd) {
      tp.push(["setAid", 'P3MUmmU9pu']);
    } else {
      tp.push(["setAid", 'o8it4JKTpu']);
    }
  `;

  const pianoAdblocker = `
    document.cookie = "__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        
    const pCookie = (adblocker) => { 
      var d = new Date(); 
      d.setTime(d.getTime() + 60 * 5 * 1000); 
      document.cookie = "__adblocker=" + (adblocker ? "true" : "false") + "; expires=" + d.toUTCString() + "; path=/"; 
    };
        
    const pscr = document.createElement("script");
    pscr.setAttribute("async", 'true'); 
    pscr.setAttribute("src", "//www.npttech.com/advertising.js");
    pscr.setAttribute("onerror", "typeof pCookie !== 'undefined' && pCookie(true)");
    document.getElementsByTagName("head")[0].appendChild(pscr);
  `;

  return (
    <>
      <Script id="piano-config">{pianoConfig}</Script>
      {pianoAdblock && <Script id="piano-adblock">{pianoAdblocker}</Script>}
    </>
  );
};

export default LoadPianoSdk;
