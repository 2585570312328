export const cloudinaryName = 'hksqkdlah';

const domain = 'https://res.cloudinary.com/hksqkdlah/image/upload';

const getOptions = (width: number, height: number) => {
  return `c_fill,dpr_2.0,f_auto,fl_lossy.progressive.strip_profile,g_faces:auto,h_${height},q_auto:low,w_${width}`;
};

export function cloudinaryURL(
  cloudinaryID: string,
  width: number,
  height: number,
) {
  // Barista occationally sends already url encoded cloudinary ids
  if (cloudinaryID.includes('%20')) {
    return `${domain}/${getOptions(width, height)}/${cloudinaryID}`;
  }
  return encodeURI(`${domain}/${getOptions(width, height)}/${cloudinaryID}`);
}
