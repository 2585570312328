'use client';

import { createGlobalStyle } from 'styled-components';
import carousel from 'legacy-mise-ui/styles/carousel';
import globalStyle from 'legacy-mise-ui/styles/global';
import { favoritesWidget, reset, globalFonts } from '../config/css';

const GlobalStyle = createGlobalStyle`${reset}${globalStyle}${carousel}${favoritesWidget}${globalFonts}`;

export default GlobalStyle;
