import { UserCtasProps } from 'components/CVMigratedNav/GlobalHeader/partials/UserCtas/UserCtas';

export type UserSegment =
  | 'anonymous'
  | 'former'
  | 'cancelled_member'
  | 'cancelled_other'
  | 'registrant'
  | 'multisite'
  | 'singleSite';

type UserCtaConfig = Record<UserSegment, UserCtasProps['links']>;

const atkUserCtaLinks: UserCtaConfig = {
  anonymous: [
    {
      href: '/order?mdc=AF0110MA1D&incode=MAHBZFTLA',
      label: 'Start Free Trial',
      className: 'user-cta-play-override',
    },
    {
      href: '/gift?incode=MAHBGMLA',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  cancelled_member: [
    {
      href: '/order?mdc=APP040MA1D&incode=MAHBZRMLE',
      label: 'Renew Now',
      className: 'user-cta-play-override',
    },
    {
      href: '/gift?incode=MAHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  cancelled_other: [
    {
      href: '/order?mdc=APP040MA1D&incode=MAHBZRMLE',
      label: 'Renew Now',
      className: 'user-cta-play-override',
    },
    {
      href: '/gift?incode=MAHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  former: [
    {
      href: '/order?mdc=APP040MA1D&incode=MAHBZRMLE',
      label: 'Renew Now',
      className: 'user-cta-play-override',
    },
    {
      href: '/gift?incode=MAHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  registrant: [
    {
      href: '/order?mdc=AF0110MA1D&incode=MAHBZFTLR',
      label: 'Start Free Trial',
      className: 'user-cta-play-override',
    },
    {
      href: '/gift?incode=MAHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  multisite: [
    {
      href: '/gift?incode=MAHBZGML0',
      label: 'Gift Membership',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
  singleSite: [
    {
      href: '/upgrade',
      label: 'Upgrade Membership',
    },
    {
      href: '/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
  ],
};

const ccoUserCtaLinks: UserCtaConfig = {
  anonymous: [
    {
      href: '/cookscountry/order?mdc=AF0110MA1D&incode=MKHBZFTLA',
      label: 'Start Free Trial',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGMLA',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  cancelled_member: [
    {
      href: '/cookscountry/order?mdc=APP040MA1D&incode=MKHBZRMLE',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  cancelled_other: [
    {
      href: '/cookscountry/order?mdc=APP040MA1D&incode=MKHBZRMLE',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  former: [
    {
      href: '/cookscountry/order?mdc=APP040MA1D',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  registrant: [
    {
      href: '/cookscountry/order?mdc=AF0110MA1D&incode=MKHBZFTLR',
      label: 'Start Free Trial',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  multisite: [
    {
      href: '/cookscountry/tv_schedule/new',
      label: 'Find TV Listings',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MKHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  singleSite: [
    {
      href: '/upgrade',
      label: 'Upgrade Membership',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      label: 'Magazine Subscription',
      target: '_blank',
    },

    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CCY&cds_page_id=265486&cds_response_key=IYH17F110',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
};

const cioUserCtaLinks: UserCtaConfig = {
  anonymous: [
    {
      href: '/cooksillustrated/access/3stmumm2?incode=MCHBZFTLA',
      label: 'Start Free Trial',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MCHBZGMLA',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  cancelled_member: [
    {
      href: '/cooksillustrated/order?mdc=APP040MA1D&incode=MCHBZRMLE',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  cancelled_other: [
    {
      href: '/cooksillustrated/order?mdc=APP040MA1D&incode=MCHBZRMLE',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  former: [
    {
      href: '/cooksillustrated/order?mdc=APP040MA1D&incode=MCHBZRMLE',
      label: 'Renew Now',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  registrant: [
    {
      href: '/cooksillustrated/access/3stmumm2?incode=MCHBZFTLR',
      label: 'Start Free Trial',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  multisite: [
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
  singleSite: [
    {
      href: '/gift?incode=MCHBZGML0',
      label: 'Gift Membership',
    },
    {
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      label: 'Magazine Subscription',
      target: '_blank',
    },
    {
      href: 'https://w1.buysub.com/servlet/ConvertibleGateway?cds_mag_code=CID&cds_page_id=265485&cds_response_key=IYB16E100',
      label: 'Gift Subscription',
      target: '_blank',
    },
  ],
};

const userCtas: Record<'atk' | 'cco' | 'cio', UserCtaConfig> = {
  atk: atkUserCtaLinks,
  cco: ccoUserCtaLinks,
  cio: cioUserCtaLinks,
};

export default userCtas;
