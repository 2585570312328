import { SET_ORIGIN } from '../actions/origin';

export const initialState = {
  isAuthenticated: false,
  siteKey: null,
  subdomain: '',
  referer: null,
  returnTo: null,
};

function originReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORIGIN:
      return action.payload;

    default:
      return state;
  }
}

export default originReducer;
