import { GetServerSideProps } from 'next';
import { Cookies } from 'react-cookie';

/** user segments visible in production page view events */
type UserSegment =
  /** User has an active multisite membership */
  | 'multisite'
  /** User has provided an email / password but has not purchased a membership */
  | 'registrant'
  /** User does not exist in user database, no user_token */
  | 'anonymous'
  /** registrant with school registration origin (should have been phased out in 2017 but exists) */
  | 'anonymous_logged_in'
  /** User has an active AKO standard or premium membership */
  | 'standard_member'
  /** User has an active CIO and/or a CCO standard or premium membership but not an AKO membership */
  | 'standard_other'
  /** User has previously had a Multisite or an AKO standard or premium membership */
  | 'cancelled_member'
  /** User has previously had a CIO/CCO standard or premium membership */
  | 'cancelled_other'
  /** User has an active App membership but has NOT completed the create account step */
  | 'app_access';

type DefaultState = {
  dfp_membership_string?: string;
  role?: string;
  segment?: UserSegment;
};

export type UserTokenState = DefaultState &
  Partial<{
    active_cds_membership: boolean;
    active_memberships: string[]; // ['cio', 'cco', 'atk', 'cookbook_collection'];
    active_registrations: string[]; // ['cio', 'cco', 'atk'];
    aud: string; //'americano123';
    cancelled_memberships: string[]; // [];
    email: string; // 'harry.scheuerle@americastestkitchen.com';
    exp: number;
    external_id: null;
    first_name: string;
    iat: number;
    id: number;
    iss: 'americastestkitchen.com';
    jti: string; //'5ad280e4a360ae280c06154f01285521';
    last_name: string;
    package_name: string; // 'Multi-Site Membership';
    piano_active_memberships: string; // '["cio","cco","atk","cookbook_collection"]';
    piano_active_registrations: string; // '["cio","cco","atk"]';
    piano_cancelled_memberships: string; // '[]';
    piano_school_segment: string; // 'anonymous';
    piano_segment: string; // 'multisite';
    sub: string;
    survey: {
      id: null;
      name: null;
      location: null;
      activeAt: null;
      viewedAt: null;
      completedAt: null;
      completionType: null;
      eligible: false;
    };
    id_digest: string;
  }>;

export type ReducedUserSegment =
  /** multisite or single site, full access */
  | 'multisite'
  /** registered email or cancelled membership, no paid access */
  | 'registrant'
  /** no registered account, no user_token */
  | 'anonymous';

/**
 * User state values we use exclusively in analytics.
 * Copied 1:1 from user_token cookie and not modified.
 */
type ReducedUserTokenStateAnalytics = {
  id: UserTokenState['id'] | null;
  external_id: UserTokenState['external_id'] | null;
  package_name: UserTokenState['package_name'] | null;
  first_name: UserTokenState['first_name'] | null;
  last_name: UserTokenState['last_name'] | null;
  email: UserTokenState['email'] | null;
  role: UserTokenState['role'];
  segment: UserSegment;
};

/**
 * user state values we use in front end logic but not analytics.
 * Modified to keep use case of BE data in line with how FE acts.
 */
type ReducedUserTokenStateFrontEnd = {
  reducedSegment: ReducedUserSegment;
};

/**
 * Reduce user state to only what we need in codebase.
 * Passes only what we need through user_token to pages.
 */
export type ReducedUserTokenState = ReducedUserTokenStateAnalytics &
  ReducedUserTokenStateFrontEnd;

export const anonymousUser: ReducedUserTokenState = {
  email: null,
  external_id: null,
  id: null,
  package_name: null,
  first_name: null,
  last_name: null,
  role: 'guest',
  segment: 'anonymous',
  reducedSegment: 'anonymous',
};

/** reduce all user segments in user_token to the ones we use and QA on FE */
function reduceUserSegment(
  segment: UserSegment | undefined,
): ReducedUserSegment {
  if (!segment) return 'anonymous';

  switch (segment) {
    case 'anonymous':
    case 'app_access':
      return 'anonymous';
    case 'anonymous_logged_in':
    case 'registrant':
    case 'cancelled_member':
    case 'cancelled_other':
      return 'registrant';
    case 'multisite':
    case 'standard_member':
    case 'standard_other':
      return 'multisite';
    default:
      return 'anonymous';
  }
}

function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  );
}

export default function parseUser(
  req?: Parameters<GetServerSideProps>['0']['req'],
): ReducedUserTokenState {
  if (!req && typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.error('[parseUser] req not provided in server call');
    return anonymousUser;
  }

  const cookies =
    typeof window === 'undefined'
      ? new Cookies(req?.headers.cookie)
      : new Cookies();

  const token = cookies.get('user_token');

  if (!token) return anonymousUser;

  try {
    const user: UserTokenState = JSON.parse(
      b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')),
    );

    return {
      id: user.id || null,
      external_id: user.external_id || null,
      package_name: user.package_name || null,
      email: user.email || null,
      first_name: user.first_name || null,
      last_name: user.last_name || null,
      role: user.role || 'guest',
      segment: user.segment || 'anonymous',
      reducedSegment: reduceUserSegment(user.segment),
    };
  } catch {
    return anonymousUser;
  }
}

export function parseUserToken(token: string): ReducedUserTokenState {
  if (!token) return anonymousUser;

  try {
    const user: UserTokenState = JSON.parse(
      b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')),
    );

    return {
      id: user.id || null,
      external_id: user.external_id || null,
      package_name: user.package_name || null,
      email: user.email || null,
      first_name: user.first_name || null,
      last_name: user.last_name || null,
      role: user.role || 'guest',
      segment: user.segment || 'anonymous',
      reducedSegment: reduceUserSegment(user.segment),
    };
  } catch {
    return anonymousUser;
  }
}
