import atk from '../lib/api/atk';

export const EMAIL_FRIEND_PENDING = 'EMAIL_FRIEND_PENDING';
export const EMAIL_FRIEND_REJECTED = 'EMAIL_FRIEND_REJECTED';
export const EMAIL_FRIEND_FULFILLED = 'EMAIL_FRIEND_FULFILLED';
export const EMAIL_FRIEND_RESET = 'EMAIL_FRIEND_RESET';

export const doEmailFriend = (url, values) => ({
  type: 'EMAIL_FRIEND',
  payload: {
    promise: atk.post(url, {
      name: values.name,
      email: values.email,
      friend_email: values.friendEmail,
      message: values.message,
    }),
  },
});

export const reset = () => ({
  type: 'EMAIL_FRIEND_RESET',
});
