import utils from '../lib/utils';

export const SET_ORIGIN = 'SET_ORIGIN';

export const setOrigin = (req) => {
  const siteKey = utils.requestToSiteKey(req) || 'atk';
  const subdomain = utils.requestToSubdomain(req);
  const isHomepage = req.headers['x-referer'] === 'homepage';
  const refererHeader = req.headers['Referer'] || req.headers['referer'];
  // TODO: fix referer here if set and clean up this func
  const refererSiteKey = isHomepage
    ? 'homepage'
    : utils.requestToSiteKey(req, refererHeader);
  const { auth_token: authToken } = utils.getCookies({ req });
  const isAuthenticated =
    typeof authToken !== 'undefined' && authToken.length > 0;
  return {
    type: SET_ORIGIN,
    payload: {
      isAuthenticated,
      referer: refererSiteKey,
      siteKey,
      subdomain,
    },
  };
};
