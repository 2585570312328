import utils from 'lib/utils';
import { useEffect, useState } from 'react';
import UserService from 'services/user.service';
import { FF_V6_LOGIN } from 'server/lib/feature-flags';
import { getCookie } from 'cookies-next';

/**
 * @typedef {'anonymous' | 'registrant' | 'singleSite' | 'former' | 'multisite'} UserSegment
 * @typedef {{
 *  activeMemberships: string[];
 *  activeRegistrations: string[];
 *  cancelledMemberships: string[];
 *  dfpMembershipString: string | null;
 *  email: string | null;
 *  firstName: string | null;
 *  id: number | null;
 *  name: string | null;
 *  packageName: null
 *  role: string | null;
 *  segment: string | null;
 *  adSegment: UserSegment | null;
 *  activeCDSMembership: boolean;
 * }} UserState
 */

/**
 * @returns {UserState | undefined} undefined while async loading user
 */
export default function useUserState() {
  const [user, setUser] = useState(undefined);
  const [user2, setUser2] = useState(undefined);
  useEffect(() => {
    if (FF_V6_LOGIN) {
      const fetchUser = async () => {
        try {
          const accessToken = getCookie('user_token');
          if (!accessToken || accessToken === 'undefined') {
            const anonymousUserState = UserService.anonUser();
            setUser2(anonymousUserState);
          } else {
            UserService.setUserFromAccessToken(`${accessToken}`);
            const v6User = UserService.user();

            setUser2(v6User);
          }
        } catch (e) {
          setUser(anonymousUserState);
        }
      };

      fetchUser();
    } else {
      let mounted = true;
      const subscription = utils.getUserData((user) => {
        const adSegment =
          user?.dfpMembershipString === 'premium'
            ? 'multisite'
            : utils.getUserSegment(user?.segment) ?? 'anonymous';

        if (mounted) {
          setUser({ ...user, adSegment });
        }
      });

      return () => {
        mounted = false;
        subscription?.remove();
      };
    }
  }, []);

  return FF_V6_LOGIN ? user2 : user;
}
