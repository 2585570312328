/* eslint-disable no-underscore-dangle */
import has from 'lodash.has';
import nextCookies from 'next-cookies';
import queryString from 'query-string';

import events from './events';
import utils from '../../utils';

function isKidsSite() {
  return (
    typeof document !== 'undefined' &&
    document.location.pathname.startsWith('/kids') &&
    document.location.pathname !== '/kids/grownups'
  );
}

export const userParams = (user, queryParams = {}) => {
  let params = {};
  const { incode, extcode } = nextCookies({ req: { headers: null } });

  if (user) {
    params = {
      incode: incode || queryParams.incode || null,
      extcode: extcode || queryParams.extcode || null,
      package: user.packageName || null,
      authenticated: !!user.authToken,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      encoded_email: encodeURIComponent(user.email),
    };
  } else {
    params = {
      incode: incode || queryParams.incode || null,
      extcode: extcode || queryParams.extcode || null,
      package: null,
    };
  }
  return params;
};

/**
 * GoogleAnalytics tracking library.
 */
function GA(user, location, cookies) {
  const currentUser = user || utils.getDefaultUser();
  const lastRef = undefined;
  const ga = window.ga;

  const trackPageView = (options) => {
    if (typeof GTM !== 'undefined') {
      setTimeout(() => {
        GTM.push(options);
      }, 250);
    }
  };

  const trackRouterChange = () => {
    if (typeof ga === 'undefined') return;
    const parsed = queryString.parse(document.location.search);
    ga('send', 'pageview');
    if (lastRef !== parsed.ref) {
      ga('set', 'Ref', parsed.ref);
    }
  };

  /**
   * Asynchronous event tracking - calls mixpanel or pushes to queue
   */
  const trackEvent = (eventName) => {
    if (
      has(events, eventName) &&
      (eventName.includes('PAGEVIEW') || eventName.includes('DOCUMENT_VIEWED'))
    ) {
      trackRouterChange();
    }
  };

  /**
   * When the user status changes, we should re-register the
   * user and re-establish the tracking variables.
   */
  const refreshUser = (user, cookies) => {
    const search = window.location.search || '';
    const isMember =
      user.activeMemberships && user.activeMemberships.length > 0;
    const isKids = isKidsSite();
    const userType = !isKids && isMember ? 'Member' : 'Visitor';
    ga('set', 'User Type', userType);
    if (!isKids && typeof GTM !== 'undefined') {
      GTM.push(userParams(user, search, cookies));
    }
  };

  const initialize = (cookies) => {
    if (typeof ga === 'undefined') return;
    /** UA keys no longer work, but parts of this could still be a dependency of gtm calls */
    let gaKey = process.env.ATK_GOOGLE_ANALYTICS_KEY;
    let optimizeKey = process.env.ATK_GOOGLE_OPTIMIZE_CONTAINER_ID;

    ga('create', gaKey, 'auto', { allowLinker: true });
    if (!isKidsSite()) {
      ga('require', optimizeKey);
    }
    ga('require', 'linker');
    const offerCardLinks = document.querySelectorAll(
      '.offer-card-link-wrapper',
    );
    for (let i = 0; i < offerCardLinks.length; i += 1) {
      ga('linker:decorate', offerCardLinks[i]);
    }
    ga('linker:autoLink', ['americastestkitchen.com', 'buysub.com']);
    ga('set', 'setDomainName', 'americastestkitchen.com');
    ga('set', 'setAllowLinker', true);
    refreshUser(currentUser, cookies);
  };

  if (typeof ga !== 'undefined') {
    initialize(cookies);
  }

  return {
    refreshUser,
    trackEvent,
    trackPageView,
    trackRouterChange,
  };
}

export default GA;
