import analytics from 'lib/analytics';
import events from 'lib/analytics/mixpanel/events';
import { Cookies } from 'react-cookie';

export default function trackUI(eventName: string, options: any) {
  const espressoEventFormat = eventName.toUpperCase().replaceAll(' ', '_');
  const espressoEventsConfig: Record<string, string> = events;
  const isEvent = typeof espressoEventsConfig[espressoEventFormat] === 'string';

  if (isEvent) {
    analytics.track(espressoEventFormat, options, {
      transport: 'sendBeacon',
    });
  } else {
    trackMixpanel(eventName, options);
  }
}

/**
 * Just track mixpanel directly if event names aren't in any mixpanel events config
 */
function trackMixpanel(...args: Parameters<typeof window.mixpanel['track']>) {
  const [event_name, properties, optionsOrCallback, callback] = args;
  const cookies = new Cookies();
  const environment = cookies.get('environment') ?? 'unassigned';
  window.mixpanel.track(
    event_name,
    {
      ...properties,
      name: event_name,
      project: 'espresso',
      pathname: window.location.pathname,
      environment,
    },
    { ...optionsOrCallback, transport: 'sendBeacon' },
    callback,
  );
}
