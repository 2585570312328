import { useRouter } from 'next/router';
import { liteClient as algoliasearch } from 'algoliasearch-new/lite';
import Header from '@americastestkitchen/ui/header/index';
import trackUI from './trackUI';
import { useUserHeader } from './useUserHeader';
import { FF_ATK25 } from 'server/lib/feature-flags';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';
const client = algoliasearch(APP_ID, API_KEY, {});

function getUserObject(user: any) {
  if (!user || user.reducedSegment === 'anonymous') {
    return { authenticated: false };
  }

  return {
    firstName: user.first_name ?? '',
    lastName: user.last_name ?? '',
    authenticated: true,
  };
}

export default function UIHeader() {
  const user = useUserHeader();
  const { asPath } = useRouter();

  return (
    <Header
      currentPath={asPath}
      algoliaClient={client}
      track={trackUI}
      user={getUserObject(user)}
      atk25FeatureFlag={FF_ATK25}
    />
  );
}
